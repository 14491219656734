import { Controller } from 'stimulus';
import Swiper, { A11y, Navigation, Pagination } from 'swiper';

// Register Swiper modules
Swiper.use([A11y, Navigation, Pagination]);

export default class extends Controller {
  static targets = ['slider', 'pagination', 'next', 'prev'];

  connect() {
    this.initSwiper();
  }

  disconnect() {
    this.destroySwiper();
  }

  /**
   * initSwiper
   * - Initialize the Swiper instance
   */
  initSwiper() {
    // Ensure Swiper is initialized only once
    if (this.swiper) return;

    this.swiper = new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      a11y: {
        enabled: true,
      },
      loop: true,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
        dynamicBullets: true,
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      speed: 450,
      on: {
        slideChange: (swiper) => {
          this.updateAriaNotification();
        },
      },
    });

    // Update the aria-notification element with the initial slide details
    this.updateAriaNotification();
  }

  /**
   * destroySwiper
   * - Destroy the Swiper instance, if it exists
   */
  destroySwiper() {
    if (this.swiper) {
      this.swiper.destroy(true, true);
      this.swiper = null;
    }
  }

  /**
   * updateAriaNotification
   * - Manually updates the swiper-notification element with slide change details
   */
  updateAriaNotification() {
    // Short-circuit if the Swiper instance is not initialized
    if (!this.swiper) return;

    // Find the swiper-notification element specific to this Swiper instance
    const notification = this.sliderTarget.querySelector('.swiper-notification');

    // Get the current slide index and total slides
    const currentSlideIndex = this.swiper?.realIndex + 1 ?? -1;
    const totalSlides = this.sliderTarget.dataset?.numSlides ?? this.swiper.slides.length;

    // If it exists and the currentSlideIndex is valid (Swiper is initialized properly)
    if (notification && currentSlideIndex > -1) {
      // Update the innerText of the notification element
      notification.innerText = `Slide ${currentSlideIndex} of ${totalSlides} is active.`;
    }
  }
}
